<template>
  <div>
    <validation-observer ref="formValidation">
      <b-form>
        <b-row class="align-content-center mx-auto">
          <b-col
            cols="12"
            sm="12"
            md="6"
          >
            <b-form-group
              label="Cliente"
              label-for="customer_id"
            >
              <validation-provider
                #default="{ errors }"
                name="cliente"
                rules="required"
              >
                <b-input-group>
                  <v-select
                    id="customer_id"
                    v-model="customer_id"
                    :state="errors.length > 0 ? false:null"
                    :dir="'ltr'"
                    label="full_name"
                    :class="`b-select ${accessPermissions('customers.store') ? 'calc-w' : 'w-100'}`"
                    :options="rowsCustomers"
                  >
                    <span slot="no-options">No hay data para cargar</span>
                  </v-select>
                  <b-input-group-append
                    v-if="accessPermissions('customers.store')"
                  >
                    <b-button
                      v-b-modal.modal-customers
                      variant="success"
                      class="btn-icon w-40px"
                    >
                      <feather-icon icon="PlusIcon" />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            sm="12"
            md="6"
          >
            <b-form-group
              label="Número de Orden"
              label-for="order_number"
            >
              <validation-provider
                #default="{ errors }"
                name="número de orden"
                rules="required"
              >
                <b-form-input
                  id="order_number"
                  v-model="order_number"
                  readonly
                  :state="errors.length > 0 ? false:null"
                  placeholder="Número de Orden"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="align-content-center mx-auto">
          <b-col
            sm="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="Fecha de Ingreso"
              label-for="date_init"
            >
              <validation-provider
                #default="{ errors }"
                name="fecha de ingreso"
                rules="required"
              >
                <b-input-group class="mb-1">
                  <b-form-input
                    id="date_init"
                    v-model="date_init"
                    :state="errors.length > 0 ? false:null"
                    type="text"
                    placeholder="YYYY-MM-DD"
                    autocomplete="off"
                    show-decade-nav
                  />
                  <b-input-group-append>
                    <b-form-datepicker
                      v-model="date_init"
                      show-decade-nav
                      button-only
                      button-variant="outline-primary"
                      right
                      size="sm"
                      locale="en-US"
                      aria-controls="example-input"
                      @context="onHandleContext"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="Hora de Ingreso"
              label-for="hour_init"
            >
              <validation-provider
                #default="{ errors }"
                name="hora de ingreso"
                rules="required"
              >
                <cleave
                  id="time"
                  v-model="hour_init"
                  class="form-control"
                  :raw="false"
                  :options="options.time"
                  placeholder="hh:mm:ss"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="Fecha de Entrega"
              label-for="date_end"
            >
              <validation-provider
                #default="{ errors }"
                name="fecha de entrega"
                rules="required"
              >
                <b-input-group class="mb-1">
                  <b-form-input
                    id="date_end"
                    v-model="date_end"
                    :state="errors.length > 0 ? false:null"
                    type="text"
                    placeholder="YYYY-MM-DD"
                    autocomplete="off"
                    show-decade-nav
                  />
                  <b-input-group-append>
                    <b-form-datepicker
                      v-model="date_end"
                      show-decade-nav
                      button-only
                      button-variant="outline-primary"
                      right
                      size="sm"
                      locale="en-US"
                      aria-controls="example-input"
                      @context="onHandleContext"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
            md="6"
          >
            <b-form-group
              label="Recibido"
              label-for="create_by"
            >
              <validation-provider
                #default="{ errors }"
                name="recibido"
                rules="required"
              >
                <v-select
                  v-model="create_by"
                  :dir="'ltr'"
                  label="full_name"
                  class="b-select"
                  :state="errors.length > 0 ? false:null"
                  :options="rowsCreated"
                >
                  <span
                    v-if="rowsCreated.length > 0"
                    slot="no-options"
                  >No hay data para cargar</span>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
            md="6"
          >
            <b-form-group
              label="Técnico"
              label-for="reviewed_by"
            >
              <v-select
                v-model="reviewed_by"
                :dir="'ltr'"
                label="full_name"
                class="b-select"
                :options="rowsRevieweds"
              >
                <span slot="no-options">No hay data para cargar</span>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            sm="12"
            md="6"
          >
            <b-form-group
              label="Equipos"
              label-for="equipment_id"
            >
              <b-input-group>
                <v-select
                  v-model="equipment_id"
                  :dir="'ltr'"
                  label="name"
                  :class="`b-select ${accessPermissions('equipment.store') ? 'calc-w' : 'w-100'}`"
                  :options="rowsEquipments"
                  multiple
                  :select-size="6"
                >
                  <span slot="no-options">No hay data para cargar</span>
                </v-select>
                <b-input-group-append
                  v-if="accessPermissions('equipment.store')"
                >
                  <b-button
                    v-b-modal.modal-equipments
                    variant="success"
                    class="btn-icon w-40px"
                  >
                    <feather-icon icon="PlusIcon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            sm="12"
            md="6"
          >
            <b-form-group
              label="Marcas"
              label-for="brand_id"
            >
              <b-input-group>
                <v-select
                  v-model="brand_id"
                  :dir="'ltr'"
                  label="name"
                  :class="`b-select ${accessPermissions('brand.store') ? 'calc-w' : 'w-100'}`"
                  :options="rowsBrands"
                  multiple
                  :select-size="6"
                >
                  <span slot="no-options">No hay data para cargar</span>
                </v-select>
                <b-input-group-append
                  v-if="accessPermissions('brand.store')"
                >
                  <b-button
                    v-b-modal.modal-brands
                    variant="success"
                    class="btn-icon w-40px"
                  >
                    <feather-icon icon="PlusIcon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            sm="12"
            md="6"
          >
            <b-form-group
              label="Accesorios"
              label-for="accessory_id"
            >
              <b-input-group>
                <v-select
                  v-model="accessory_id"
                  :dir="'ltr'"
                  label="name"
                  :class="`b-select ${accessPermissions('accessory.store') ? 'calc-w' : 'w-100'}`"
                  :options="rowsAccessories"
                  multiple
                  :select-size="6"
                >
                  <span slot="no-options">No hay data para cargar</span>
                </v-select>
                <b-input-group-append
                  v-if="accessPermissions('accessory.store')"
                >
                  <b-button
                    v-b-modal.modal-accessories
                    variant="success"
                    class="btn-icon w-40px"
                  >
                    <feather-icon icon="PlusIcon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
            md="6"
          >
            <b-form-group
              label="Número de Serie"
              label-for="number_serie"
            >
              <b-form-input
                id="number_serie"
                v-model="number_serie"
                placeholder="Número de Serie"
              />
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="Daño encontrado"
              label-for="failures"
            >
              <validation-provider
                #default="{ errors }"
                name="daño encontrado"
                rules="required"
              >
                <b-form-textarea
                  id="failures"
                  v-model="failures"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Daño encontrado"
                  rows="4"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="Observaciones"
              label-for="hits"
            >
              <b-form-textarea
                id="hits"
                v-model="hits"
                placeholder="Observaciones"
                rows="4"
              />
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="Informe Técnico"
              label-for="description"
            >
              <b-form-textarea
                id="description"
                v-model="description"
                placeholder="Informe Técnico"
                rows="4"
              />
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
            md="6"
            lg="4"
          >
            <b-form-group
              label="Hacer Respaldo"
              label-for="recovery"
            >
              <validation-provider
                #default="{ errors }"
                name="respaldo"
                rules="required"
              >
                <b-form-radio
                  v-model="recovery"
                  :state="errors.length > 0 ? false:null"
                  name="recovery"
                  class="float-left mb-2"
                  value="Si"
                >
                  Si
                </b-form-radio>
                <b-form-radio
                  v-model="recovery"
                  :state="errors.length > 0 ? false:null"
                  name="recovery"
                  class="float-left ml-2 mb-2"
                  value="No"
                >
                  No
                </b-form-radio>
                <div class="w-100 float-left">
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="align-content-center mx-auto">
          <b-col
            sm="12"
            md="12"
          >
            <b-button
              class="float-right text-right"
              variant="primary"
              type="submit"
              @click.prevent="onHandleValidationForm"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              Guardar
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              class="float-right mr-1"
              :to="{ name: 'workshops-orders' }"
            >
              Cancelar
            </b-button>
            <b-button
              v-if="this.id !== undefined && status !== 4"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              class="float-right mr-1"
              @click="onHandleCancel"
            >
              Finalizar Orden
            </b-button>
            <b-button
              v-if="this.id !== undefined"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="info"
              class="float-right mr-1"
              @click="onHandleResendEmail"
            >
              <feather-icon
                icon="MailIcon"
                class="mr-50"
              />
              Reenviar Email
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <b-modal
      v-if="accessPermissions('customers.store')"
      id="modal-customers"
      ref="customers-modal"
      centered
      hide-backdrop
      hide-footer
      no-close-on-backdrop
      content-class="shadow"
      size="lg"
      title="Agregar un nuevo Cliente"
      @hidden="hideCustomerModal"
    >
      <b-card-body>
        <FormCreateClientComponent
          root="modal"
          endPoint="customers"
          @setHiddenModal="hideCustomerModal"
        />
      </b-card-body>
    </b-modal>
    <b-modal
      v-if="accessPermissions('accessory.store')"
      id="modal-accessories"
      ref="accessories-modal"
      centered
      hide-backdrop
      hide-footer
      no-close-on-backdrop
      content-class="shadow"
      size="lg"
      title="Agregar un nuevo Accesorio"
      @hidden="hideAccessoryModal"
    >
      <b-card-body>
        <FormCreateAccessoryComponent
          root="modal"
          @setHiddenModal="hideAccessoryModal"
        />
      </b-card-body>
    </b-modal>
    <b-modal
      v-if="accessPermissions('brand.store')"
      id="modal-brands"
      ref="brands-modal"
      centered
      hide-backdrop
      hide-footer
      no-close-on-backdrop
      content-class="shadow"
      size="lg"
      title="Agregar un nueva Marca"
      @hidden="hideBrandModal"
    >
      <b-card-body>
        <FormCreateBrandComponent
          root="modal"
          @setHiddenModal="hideBrandModal"
        />
      </b-card-body>
    </b-modal>
    <b-modal
      v-if="accessPermissions('equipment.store')"
      id="modal-equipments"
      ref="equipments-modal"
      centered
      hide-backdrop
      hide-footer
      no-close-on-backdrop
      content-class="shadow"
      size="lg"
      title="Agregar un nuevo Equipo"
      @hidden="hideEquipmentModal"
    >
      <b-card-body>
        <FormCreateEquipmentComponent
          root="modal"
          @setHiddenModal="hideEquipmentModal"
        />
      </b-card-body>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCardBody,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BForm,
  BFormDatepicker,
  BInputGroup,
  BInputGroupAppend,
  BFormTextarea,
  BFormRadio,
  BModal,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'
import { required } from '@validations'
import vSelect from 'vue-select'
import router from '@/router'
import FormCreateClientComponent from '@/pages/dashboard/customers/components/FormCreateComponent.vue'
import FormCreateAccessoryComponent from '@/pages/dashboard/workshops/accessories/components/FormCreateComponent.vue'
import FormCreateBrandComponent from '@/pages/dashboard/workshops/brands/components/FormCreateComponent.vue'
import FormCreateEquipmentComponent from '@/pages/dashboard/workshops/equipments/components/FormCreateComponent.vue'
import CustomersService from '@/pages/dashboard/customers/services/CustomersService'
import AgentsServices from '@/pages/dashboard/settings/agents/services/AgentsServices'
import EquipmentsService from '@/pages/dashboard/workshops/equipments/services/EquipmentsService'
import BrandsService from '@/pages/dashboard/workshops/brands/services/BrandsService'
import AccessoriesService from '@/pages/dashboard/workshops/accessories/services/AccessoriesService'
import OrdersService from '../services/OrdersService'
import errorsServices from '@/libs/errorsServices'
import permissions from '@/libs/permissions'
import moment from 'moment'

export default {
  name: 'FormCreateComponent',
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BCardBody,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BForm,
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
    BFormTextarea,
    BFormRadio,
    BModal,
    Cleave,
    FormCreateClientComponent,
    FormCreateAccessoryComponent,
    FormCreateBrandComponent,
    FormCreateEquipmentComponent,
  },
  props: {
    id: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      options: {
        time: {
          time: true,
          timePattern: ['h', 'm', 's'],
        },
      },
      customer_id: '',
      order_number: '',
      date_init: '',
      date_end: '',
      hour_init: '12:00:00',
      create_by: '',
      reviewed_by: '',
      equipment_id: '',
      brand_id: '',
      accessory_id: '',
      number_serie: '',
      failures: '',
      hits: '',
      description: '',
      recovery: '',
      status: '',
      formatted: '',
      selected: '',
      rowsRevieweds: [],
      rowsCreated: [],
      rowsCustomers: [],
      rowsEquipments: [],
      rowsBrands: [],
      rowsAccessories: [],
      required,
    }
  },
  mounted() {
    this.hour_init = moment(new Date()).format('hh:mm:ss')
  },
  async created() {
    await this.onHandleEdit()
  },
  methods: {
    accessPermissions(permission){
      return permissions(permission)
    },
    explodeDate(date) {
      let value = null

      if (date !== null) {
        const explodeD = date.split(' ')
        value = explodeD[0]
      }

      return value
    },
    errorResp(error) {
      const err = errorsServices(error)

      this.swal(err, 'error')
    },
    swal(text, type) {
      this.$swal({
        title: text,
        icon: type,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: 'Aceptar',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        showClass: {
          popup: 'animate__animated animate__bounceIn',
        },
        buttonsStyling: false,
      })
    },
    clearForm() {
      this.customer_id = ''
      this.order_number = ''
      this.date_init = ''
      this.date_end = ''
      this.hour_init = ''
      this.reviewed_by = ''
      this.create_by = ''
      this.equipment_id = ''
      this.brand_id = ''
      this.accessory_id = ''
      this.number_serie = ''
      this.failures = ''
      this.hits = ''
      this.description = ''
      this.recovery = ''
      this.status = ''
      this.formatted = ''
      this.selected = ''
      this.rowsRevieweds = []
      this.rowsCreated = []
      this.rowsCustomers = []
      this.rowsEquipments = []
      this.rowsBrands = []
      this.rowsAccessories = []
      this.$refs.formValidation.reset()
    },
    onHandleContext(ctx) {
      this.formatted = ctx.selectedFormatted
      this.selected = ctx.selectedYMD
    },
    state() {
      return Boolean(this.value)
    },
    hideCustomerModal() {
      this.onHandleCustomersList()
      this.$refs['customers-modal'].hide()
    },
    hideAccessoryModal() {
      this.onHandleAccessoriesList()
      this.$refs['accessories-modal'].hide()
    },
    hideBrandModal() {
      this.onHandleBrandsList()
      this.$refs['brands-modal'].hide()
    },
    hideEquipmentModal() {
      this.onHandleEquipmentsList()
      this.$refs['equipments-modal'].hide()
    },
    async onHandleOrderNumberFacture() {
      if (this.id === undefined && this.accessPermissions('order.number.facture')) {
        await OrdersService.handleNumberFacture().then(response => {
          this.order_number = response.data.data.number
        }).catch(error => this.errorResp(error))
      }
    },
    async onHandleAgentList() {
      await AgentsServices.handleList().then(response => {
        const data = []

        if (response.data.data.length > 0) {
          response.data.data.map(item => {
            data.push({ id: item.id, full_name: item.profile.full_name })
          })
        }

        this.rowsRevieweds = data.length === 0 ? null : data
        this.rowsCreated = data.length === 0 ? null : data
      }).catch(error => this.errorResp(error))
    },
    async onHandleCustomersList() {
      await CustomersService.handleList().then(response => {
        this.rowsCustomers = response.data.data
      }).catch(error => this.errorResp(error))
    },
    async onHandleEquipmentsList() {
      await EquipmentsService.handleList().then(response => {
        const equipments = []

        if (response.data.data.length > 0) {
          response.data.data.map(({ id, name }) => {
            equipments.push({ id: id, name: name })
          })
        }

        this.rowsEquipments = equipments
      }).catch(error => this.errorResp(error))
    },
    async onHandleBrandsList() {
      await BrandsService.handleList().then(response => {
        const brands = []

        if (response.data.data.length > 0) {
          response.data.data.map(({ id, name }) => {
            brands.push({ id: id, name: name })
          })
        }

        this.rowsBrands = brands
      }).catch(error => this.errorResp(error))
    },
    async onHandleAccessoriesList() {
      await AccessoriesService.handleList().then(response => {
        const accessories = []

        if (response.data.data.length > 0) {
          response.data.data.map(({ id, name }) => {
            accessories.push({ id: id, name: name })
          })
        }

        this.rowsAccessories = accessories
      }).catch(error => this.errorResp(error))
    },
    async onHandleResendEmail() {
      if (this.id !== undefined) {
        await OrdersService.handleResendEmail(this.id).then(response => {
          if (response.data.data) {
            this.swal('Email ha sido reenviado correctamente', 'success')
          }
        }).catch(error => this.errorResp(error))
      }
    },
    async onHandleCancel() {
      if (this.id !== undefined && this.accessPermissions('order.closed')) {
        await OrdersService.handleClosed(this.id).then(response => {
          if (response.data.data) {
            this.swal('La orden ha sido cancelada correctamente', 'success')
            router.push({ name: 'workshops-orders' })
          }
        }).catch(error => this.errorResp(error))
      }
    },
    async onHandleEdit() {
      if (this.id === undefined && this.accessPermissions('order.number.facture')) {
        await this.onHandleOrderNumberFacture()
      }

      await this.onHandleAgentList()
      await this.onHandleCustomersList()
      await this.onHandleEquipmentsList()
      await this.onHandleBrandsList()
      await this.onHandleAccessoriesList()

      if (this.id !== undefined && this.accessPermissions('order.show')) {
        await OrdersService.handleEdit(this.id).then(response => {
          let create = ''
          let reviewed = ''
          const equipments = []
          const brands = []
          const accessories = []

          if (response.data.data.create !== null) {
            create = {
              id: response.data.data.create.id,
              full_name: response.data.data.create.profile === null ? '' : response.data.data.create.profile.full_name,
            }
          }

          if (response.data.data.reviewed !== null) {
            reviewed = {
              id: response.data.data.reviewed.id,
              full_name: response.data.data.reviewed.profile === null ? '' : response.data.data.reviewed.profile.full_name,
            }
          }

          if (response.data.data.equip !== null) {
            const arrayEquip = response.data.data.equip.split(',')

            arrayEquip.map(item => {
              equipments.push({ name: item })
            })
          }

          if (response.data.data.brand !== null) {
            const arrayBrand = response.data.data.brand.split(',')

            arrayBrand.map(item => {
              brands.push({ name: item })
            })
          }

          if (response.data.data.accessory !== null) {
            const arrayAccesory = response.data.data.accessory.split(',')

            arrayAccesory.map(item => {
              accessories.push({ name: item })
            })
          }

          this.customer_id = response.data.data.customer
          this.order_number = `00000000${response.data.data.order_number}`
          this.date_init = this.explodeDate(response.data.data.date_init)
          this.date_end = this.explodeDate(response.data.data.date_end)
          this.hour_init = response.data.data.hour_init
          this.create_by = create === '' ? '' : create
          this.reviewed_by = reviewed === '' ? '' : reviewed
          this.equipment_id = equipments.length === 0 ? '' : equipments
          this.brand_id = brands.length === 0 ? '' : brands
          this.accessory_id = accessories.length === 0 ? '' : accessories
          this.number_serie = response.data.data.number_serie
          this.failures = response.data.data.failures
          this.hits = response.data.data.hits
          this.description = response.data.data.description
          this.recovery = response.data.data.recovery === 1 ? 'Si' : 'No'
          this.status = response.data.data.status
        }).catch(error => this.errorResp(error))
      }
    },
    async onHandleValidationForm() {
      await this.$refs.formValidation.validate().then(success => {
        if (success) {
          const equipments = []
          const brands = []
          const accessories = []

          if (this.equipment_id.length > 0) {
            this.equipment_id.map(({ name }) => {
              equipments.push(name)
            })
          }

          if (this.brand_id.length > 0) {
            this.brand_id.map(({ name }) => {
              brands.push(name)
            })
          }

          if (this.accessory_id.length > 0) {
            this.accessory_id.map(({ name }) => {
              accessories.push(name)
            })
          }

          const data = {
            customer_id: !this.customer_id || !this.customer_id.id ? null : this.customer_id.id,
            order_number: this.order_number,
            date_init: this.date_init,
            date_end: this.date_end,
            hour_init: this.hour_init,
            create_by: this.create_by.id,
            reviewed_by: !this.reviewed_by || !this.reviewed_by.id ? null : this.reviewed_by.id,
            equip: this.equipment_id.length === 0 ? null : equipments.toString(),
            brand: this.brand_id.length === 0 ? null : brands.toString(),
            accessory: this.accessory_id.length === 0 ? null : accessories.toString(),
            number_serie: this.number_serie,
            failures: this.failures,
            hits: this.hits,
            description: this.description,
            recovery: this.recovery === 'Si' ? 1 : 0,
          }

          if (this.id === undefined && this.accessPermissions('order.store')) {
            OrdersService.handleStore(data).then(response => {
              if (response.data.data) {
                this.swal('La orden ha sido registrado correctamente', 'success')
                this.clearForm()
                router.push({ name: 'workshops-orders' })
              }
            }).catch(error => this.errorResp(error))
          } else if (this.id !== undefined && this.accessPermissions('order.update')) {
            OrdersService.handleUpdate(this.id, data).then(response => {
              if (response.data.data) {
                this.swal('La orden ha sido actualizado correctamente', 'success')
                this.clearForm()
                router.push({ name: 'workshops-orders' })
              }
            }).catch(error => this.errorResp(error))
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
.b-select {
  min-height: 36px !important;
  //border: 1px solid rgba(60, 60, 60, 0.26) !important;
  border-radius: 4px !important;
  width: auto;
}

.vs__selected-options {
  min-height: 30px !important;
}

.calc-w {
  width: calc(100% - 40px) !important;
}

.w-40px {
  width: 40px !important;
}
</style>
