import ordersDefaultConfig from './OrdersDefaultConfig'
import axiosIns from '@/libs/axios'

class OrdersService {
    config = { ...ordersDefaultConfig }

    axios = null

    constructor() {
      this.axios = axiosIns
    }

    async handleNumberFacture() {
      const result = await this.axios.get(this.config.numberEndpoint)

      return result
    }

    async handleList() {
      const result = await this.axios.get(this.config.indexEndpoint)

      return result
    }

    async handleStore(data) {
      const result = await this.axios.post(this.config.storeEndpoint, data)

      return result
    }

    async handleEdit(id) {
      const result = await this.axios.get(`${this.config.editEndpoint}${id}`)

      return result
    }

    async handleUpdate(id, data) {
      const result = await this.axios.put(`${this.config.updateEndpoint}${id}`, data)

      return result
    }

    async handleResendEmail(id) {
      const result = await this.axios.get(`${this.config.resendEmailEndpoint}${id}`)

      return result
    }
    
    async handleClosed(id) {
      const result = await this.axios.put(`${this.config.closedEndpoint}${id}`)

      return result
    }

    async handlePrint(id) {
      const result = await this.axios.get(`${this.config.printEndpoint}${id}`)

      return result
    }
    
    async handleDelete(id) {
      const result = await this.axios.delete(`${this.config.deleteEndpoint}${id}`)

      return result
    }
}

export default new OrdersService()
