export default {
  numberEndpoint: '/number-facture',
  indexEndpoint: '/order',
  storeEndpoint: '/order',
  editEndpoint: '/order/',
  updateEndpoint: '/order/',
  resendEmailEndpoint: '/send-email-order/',
  closedEndpoint: '/order/closed/',
  printEndpoint: '/download-order/',
  deleteEndpoint: '/order/',
}
